.arrow {
  margin: 4px 10px 0 0;
  border: solid;
  border-width: 0 3px 3px 0;
  padding: 5px;
  font-size: 0;

  display: none;

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  transition: ease 0.7s;

  &.close-arrow {

    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

/* Accordion Contentelement */
.accordion-wrap {

  .accordion {
    display: flex;
  }
}

// Accordion Height
$a-height: 460px;

// Position text along bottom
$text-offset: $a-height - 90;

.accordion-wrap {
  width: 100%;
  max-width: $wrapper-max-width;
  height: $a-height;
  overflow: hidden;
  margin: 50px auto;

  .accordion {
    width: 100%;
    display: table;
    table-layout: fixed;
    margin: 0;
    padding: 0;

    .accordion-content {
      display: table-cell;
      vertical-align: bottom;
      position: relative;
      width: 16.666%; // 6 into 100
      height: $a-height;

      border: 1px solid $color-primary;

      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      transition: all 500ms ease;

      .mobile-accordion-opener {
        display: none;
      }

      h3 {
        color: $color-white;
        font-size: 1.1rem;
      }

      div {
        display: block;
        overflow: hidden;
        width: 100%;

        .column-text-wrap {
          display: block;
          height: $a-height;
          width: 100%;

          position: relative;
          z-index: 3;
          vertical-align: bottom;
          padding: 10px 15px;
          box-sizing: border-box;
          color: #fff;
          text-decoration: none;
          font-family: Open Sans, sans-serif;

          transition: all 200ms ease;

          * {
            opacity: 0;
            margin: 0;
            text-overflow: ellipsis;
            position: relative;
            z-index: 5;

            overflow: hidden;

            -webkit-transition: all 400ms ease;
            transition: all 400ms ease;

          }

          h3 {
            opacity: 1;
            bottom: 0;
            position: absolute;
            width: 100%;
            padding: 19px;
            margin: 0 -15px;
            font-style: normal;
            font-weight: 600;
            background: $color-primary-transparent;
            text-overflow: clip;
            text-transform: uppercase;

            -webkit-transition: all 400ms ease;
            transition: all 400ms ease;
          }

          .column-text {
            position: initial;
            margin-top: 100px;
            width: 80%;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
            }


            -webkit-transition: all 200ms ease;
            transition: all 200ms ease;

            .link-button {
              width: initial;
              display: inline-block;
            }

            p {
              position: initial;
              top: $text-offset;

            }
          }
        }
      }
    }

    &:hover .accordion-content,
    &:focus-within .accordion-content {
      width: 8%;
    }

    .accordion-content:focus {
      outline: none;
    }

    &:hover .accordion-content:hover,
    .accordion-content:focus,
    &:focus-within li:focus {
      width: 60%;

      .column-text-wrap {
        background: $color-primary-transparent;

        * {
          opacity: 1;
          -webkit-transform: translateX(0);
          transform: translateX(0);

        }

        h3 {
          bottom: calc(100% - 70px);
        }

        .column-text {
          -webkit-transition: all 800ms ease;
          transition: all 800ms ease;
          transition-delay: 400ms;
        }
      }
    }

    &:hover .accordion-content {
      width: 8% !important;

      .column-text-wrap * {
        opacity: 0 !important;
      }
    }

    &:hover .accordion-content:hover {
      width: 60% !important;

      .column-text-wrap {
        background: $color-primary-transparent;

        * {

          opacity: 1 !important;
          -webkit-transform: translateX(0);
          transform: translateX(0);
        }
      }
    }
  }
}

// Responsive Style for Accordion Contentelement

@media screen and (max-width: $breakpoint-small) {
  .accordion-wrap {
    height: auto;

    .accordion, .accordion:hover {

      .accordion-content, .accordion-content:hover {
        position: relative;
        display: block;
        table-layout: fixed;
        width: 100% !important;
        height: auto;
        margin-bottom: 5px;

        -webkit-transition: none;
        transition: none;

        .mobile-accordion-opener {
          background: $color-primary;
          color: #fff;
          display: block;

          h3 {
            float: left;
            margin: 0;
            font-weight: 600;
            font-style: normal;
            text-transform: uppercase;
            padding: 15px;
            width: 70%;

            br {
              display: none;
            }
          }

          .arrow {
            float: right;
            width: auto;
            margin: 20px 15px 15px 15px;
          }
        }

        .accoordion-content-inner-wrap {
          display: none;

          .column-text-wrap {
            height: auto;
            background: $dark-transparent;
            opacity: 1 !important;

            * {
              opacity: 1 !important;
            }

            h3 {
              display: none;
            }

            .column-text {
              margin-top: 25px;
            }
          }
        }
      }
    }
  }
}

