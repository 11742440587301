#lage {

  .svg-square {
    right: 0;
  }

}

.img-box-lage {
  position: relative;
  width: 100%;

  img:first-child {
    margin-bottom: -5px;
  }

  .svg-square {
    position: absolute;
    top: 5%;
    right: 15%;
    margin-left: 15px;

    @media (max-width: $breakpoint-large) {
      right: 7%;
    }

    @media (max-width: $breakpoint-medium) {

      right: 5%;
    }

    @media (max-width: $breakpoint-mobile) {
      right: 2.5%;
    }
  }


  .img-box-lageright-overlay {
    position: absolute;
    bottom: 0;
    margin-bottom: -1px;
    width: 100%;
    height: 100px;
    background-color: $color-secondary-transparent;
    z-index: 20;

    @media (max-width: $breakpoint-medium) {
      height: 80px;
    }

    @media (max-width: $breakpoint-smaller) {
      height: 60px;
      margin-bottom: -2px;
    }

    @media (max-width: $breakpoint-mobile) {
      height: 40px;
    }
  }
}


