.main-wrap {
  width: 80%;
  max-width: $wrapper-max-width;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: $breakpoint-large) {
    width: 85%;
    min-width: 1px;
  }

  @media (max-width: $breakpoint-medium) {
    width: 90%;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 95%;
  }

}

.second-wrap {
  padding-right: 10%;
}

.content-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2% 0;
  gap: 10px;

  @media (max-width: $breakpoint-small) {
    flex-direction: column;
  }
}

.content-box-inner {
  display: flex;
  justify-content: space-between;
  margin: 2% 0;


  @media (max-width: $breakpoint-smallest) {
    flex-direction: column;
  }


  @media (max-width: $breakpoint-smallest) {
    flex-direction: column;
  }
}

.box-20 {
  width: 20%;
}

.box-25 {
  width: 25%;
}

.box-35 {
  width: 35%;
}

.box-50{
  width:50%
}

.box-60 {
  width: 60%;
}

.box-65 {
  width: 65%;
  margin-right: 20px;
}

.box-68 {
  width: 68%;
}

.box-70 {
  width: 70%;
}

.box-75 {
  width: 75%;
}

.box-80 {
  width: 80%;
}

@media (max-width: $breakpoint-large) {

  .box-68 {
    width: 80%;
  }
}

@media (max-width: $breakpoint-medium) {
  .box-60 {
    width: 100%;
  }

  .box-80 {
    width: 100%;
  }

  .box-70 {
    width: 100%;
  }

  .box-68 {
    width: 100%;
  }
}

@media (max-width: $breakpoint-small) {
.box-20 {
  width: 45%;
}

  .box-25 {
    width: 100%;
  }

  .box-50{
    width: 100%;
  }

  .box-75 {
    width: 100%;
  }
}

// breakpoints für die grundrisse boxen
@media (max-width: $breakpoint-medium-small-2) {
  .box-35 {
    width: 100%;
  }

  .box-65 {
    width: 100%;
  }
}