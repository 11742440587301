#expose {
  // ------------ offset for trenner-heading - START

  margin-top: 50px;

  @media (max-width: 787px) {
    margin-top: 100px;
  }

  @media (max-width: 600px) {
    margin-top: 70px;
  }

  // ------------ offset for trenner-heading - END

  form {
    margin-top: 50px;
    margin-bottom: 50px;

    display: flex;
    flex-wrap: wrap;

    @media (max-width: $breakpoint-smaller) {
      flex-direction: column;
      margin-bottom: 0;
    }


    .form-item {
      padding: 10px 20px 0 0;
      flex: 0 50%;

      &:first-child {
        flex: 0 100%;
        padding: 10px 40px 0 0;

        input {
          width: 50%;
        }

        @media (max-width: $breakpoint-smaller) {
          padding: 10px 20px 0 0;

          input {
            width: 100%;
          }
        }


      }

      display: flex;
      flex-direction: column;
    }

    input[type=text],
    input[type=email] {
      box-sizing: border-box;
      height: 35px;
      padding: 0 1%;

      background-color: $color-light-grey;
      border: 1px solid $color-primary;
    }

    .checkbox-submit-wrapper {
      display: flex;
      margin-top: 35px;

      @media (max-width: $breakpoint-smaller) {
        flex-direction: column;
      }


      .button-wrapper {
        flex: 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $breakpoint-smaller) {
          margin-top: 10px;
          justify-content: flex-start;
        }

        button {
          cursor: pointer;
        }
      }
    }


    /* --------------- custom checkbox styles - START -------------- */

    /* Customize the label (the checkbox-container) */
    .checkbox-container {
      display: block;
      position: relative;
      padding: 0 20px 0 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 0.8rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      flex: 0 50%;
    }

    /* Hide the browser's default checkbox */
    .checkbox-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      //background-color: #eee;
      border: 2px solid $color-secondary
    }

    /* On mouse-over, add a grey background color */
    .checkbox-container:hover input ~ .checkmark {
      background-color: $color-light-grey;
    }

    /* When the checkbox is checked, add background */
    .checkbox-container input:checked ~ .checkmark {
      background-color: $color-secondary;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .checkbox-container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkbox-container .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    /*  --------------- custom checkbox styles - END -------------- */


  }
}
