// =========
/// Colors
// =========

$color-primary: #952738;
$color-primary-transparent: rgba(149, 39, 56, 0.9);
$color-secondary: #c1aa4c;
$color-secondary-transparent: rgba(187, 167, 86, 0.6);
$color-secondary-pale: rgba(193, 170, 76, 0.2);
$color-black: #000;
$color-dark-grey: #425658;
$color-white: #fff;
$color-grey: #ccc;
$color-light-grey: #f7f7f7;
$dark-transparent: rgba(0,0,0,0.75);


// Wrapper max width
$wrapper-max-width: 1200px;

// media queries - breakpoints

$breakpoint-large: 1400px;
$breakpoint-navi: 740px;
$breakpoint-medium-large: 1200px;
$breakpoint-medium: 1000px;
$breakpoint-medium-small: 900px;
$breakpoint-medium-small-2: 975px;
$breakpoint-small: 800px;
$breakpoint-smaller: 600px;
$breakpoint-smallest: 500px;
$breakpoint-mobile: 400px;


// Linzgaublick
$color-primary-light:  rgba(218,217,215,1);
$color-primary-light1: rgba(222,223,220,1);
$color-primary-light2:  rgba(244,244,243,1);
$color-primary-light3:  rgba(234,234,232,1);
$color-primary-trans: rgba(222,222,220,0.95);
$color-primary-trans1: rgba(222,222,220,0.70);
$color-primary-border: #878787;
$color-secondary-dark: rgba(105,106,105,1);
$color-dark-trans: rgba(105,106,106,0.9);
$color-dark: rgba(105,106,105,1);


$font-text: 'Open Sans';
$font-header: 'Open Sans';

// =========
// fonts > font-sizes
// =========
$fontsize10: 10;
$fontsize20: 12;
$fontsize30: 13;
$fontsize40: 15;
$fontsize45: 18;
$fontsize50: 18;
$fontsize60: 24;
$fontsize70: 26;
$fontsize80: 28;



