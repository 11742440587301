#ueberuns {

  padding-top: 4%;

  @media (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
    .margin-left {
      margin-left: 30px;
    }
  }


  .content-box {
    @media (max-width: $breakpoint-medium-small) {
      flex-direction: column;
    }
  }

  .content-box-inner {
    display: block;
    margin: 30px 0;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
    color: $color-primary;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-secondary;
    }
  }


  .img-border {
    display: inline-block;
    position: relative;

    img {
      width: 250px;
      height: auto;
      margin-bottom: 20px;

      //@media (max-width: $breakpoint-medium) {
      //  width: 150px;
      //}

    }
  }

  .img-border:after {
    position: absolute;
    width: 100%;
    height: 100%;

    //if img-container gets out of 1:1 range
    max-width: 250px;
    max-height: 250px;
    top: -3%;
    left: -3%;
    z-index: 10;
    outline: 3px solid $color-secondary;
    outline-offset: -5px;
    content: "";

    //@media (max-width: $breakpoint-medium) {
    //  max-width: 150px;
    //  max-height: 150px;
    //  left: -4%;
    //}
    //
    //@media (max-width: $breakpoint-medium-small) {
    //  max-width: 150px;
    //  max-height: 150px;
    //  left: -4%;
    //}
    //
    //@media (max-width: $breakpoint-smallest) {
    //  top: -4%;
    //  left: -2%;
    //  max-width: 153px;
    //  max-height: 155px;
    //}

    //@media (max-width: $breakpoint-mobile) {
    //  top: -5%;
    //  left: -3%;
    //  max-width: 153px;
    //  max-height: 152px;
    //}
  }

  .description {
    margin-right: 10%;

    //@media (max-width: $breakpoint-smallest) {
    //  margin-right: 2%;
    //}
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    li {
      flex: 0 24.5%;
      background: url("../img/1.png") no-repeat 7px 7px transparent;
      list-style-type: none;
      padding: 0.4% 2% 2% 4%;
      vertical-align: middle;
      margin-left: 0;


      h5 {

        @media (max-width: $breakpoint-small) {
          padding-left: 2%
        }
        @media (max-width: $breakpoint-smaller) {
          padding-left: 4%
        }

        @media (max-width: $breakpoint-mobile) {
          padding-left: 5%
        }
      }
    }

    @media (max-width: $breakpoint-small) {
      flex-direction: column;
    }

  }


}