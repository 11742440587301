/* ----- Header - Emobereich - Navgation ------- */

header {
  position: relative;

  .nav_box {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 770px;
    border-left: 10px solid white;
    border-right: 10px solid white;
    border-bottom: 10px solid white;

    @media (max-width: $breakpoint-navi) {
      max-width: 300px;
    }




    .logo {
      text-align: center;

      img {
        margin-top: 20px;
        width: 180px;

        @media (max-width: $breakpoint-navi) {
          width: 150px;
        }

        @media (max-width: $breakpoint-mobile) {
          width: 120px;
        }
      }
    }
  }

  .emo-img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .sub-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    height: 150px;
    margin-bottom: 23%;
    background-color: $color-primary;

    padding-right: 10%;

    @media (max-width: $breakpoint-large) {
      padding-right: 7.5%;
    }

    @media (max-width: $breakpoint-medium) {
      padding-right: 0;
      margin-bottom: 0;
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media (max-width: $breakpoint-medium) {
        width: 100%;
        justify-content: center;
      }

      .icon {
        height: 40px;
        width: auto;

        @media (max-width: $breakpoint-large) {
          height: 38px;
        }

        @media (max-width: $breakpoint-medium) {
          height: 35px;
        }

        @media (max-width: $breakpoint-mobile) {
          height: 30px;
        }
      }

      p {
        text-transform: uppercase;
        margin-top: 20px;
        color: $color-white;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;


        @media (max-width: $breakpoint-mobile) {
          font-size: 0.7rem;
        }
      }
    }


  }

  .sub-nav-img-mobile {
    display: none;
    margin-top: 20px;
  }

  @media (max-width: $breakpoint-medium) {
    .box-50 {
      display: none;
    }

    .sub-nav-img-mobile {
      display: block;
    }
  }
}


