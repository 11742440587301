#grundrisse {

  .margin-bottom {
    margin-bottom: 30px;
  }
  // ------------ offset for trenner-heading - START

  margin-top: 50px;

  @media (max-width: 787px) {
    margin-top: 100px;
  }

  @media (max-width: 600px) {
    margin-top: 70px;
  }

  // ------------ offset for trenner-heading - END

  .tab-link {
    width: 32%;
    text-align: center;

    img {
      max-width: 300px;
    }
  }

  ul.tabs {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: $breakpoint-medium) {
      display: none;
    }

  }

  ul.tabs li {
    display: inline-block;
  }

  .tab-content {
    display: none;

    @media (max-width: $breakpoint-medium-small) {
      .content-box {
        flex-direction: column;
      }
    }

    .heading {

      text-align: center;

      p:first-child {
        text-transform: uppercase;
        color: $color-primary;
        font-weight: 800;
      }

      p:nth-child(2) {
        text-transform: uppercase;
        font-weight: 800;
        color: $color-dark-grey;

      }
    }

    .box-50 {
      @media (max-width: $breakpoint-medium-small) {
        width: 100%;
      }
    }
  }

  .tab-content.current {
    display: inherit;
    transition: all 1s ease;
  }


  // Tabs secondary styles
  .tabs-secondary-container {
    margin: 5% 0;
    display: flex;
    transition: transform 0.2s;

    // scrollbar
    @media (max-width: $breakpoint-smallest) {
      overflow-x: scroll;
    }


    // active styles der Tabs Boxen

    .tabs-secondary.active {
      padding-left: 15px;

      @media (max-width: $breakpoint-mobile) {
        padding-left: 5px;
      }




      .img,
      .reiter-box {
        transition: transform 0.2s, opacity 0.2s;
        transform: scale(1);
      }
    }

    .tabs-secondary-a.active {
      border-right: 1px solid $color-primary;
    }

    .tabs-secondary-b.active {
      border-right: 1px solid $color-primary;
      border-left: 1px solid $color-primary;
    }

    .tabs-secondary-c.active {
      border-left: 1px solid $color-primary;
    }

    .tabs-secondary-a.passiv,
    .tabs-secondary-b.passiv,
    .tabs-secondary-c.passiv {
      border-bottom: 1px solid $color-primary;
      padding: 0;

      .img,
      .reiter-box {
        transition: transform 0.2s, opacity 0.2s;
        transform: scale(0.85);
        opacity: 0.6;

        &:before {
          width: 1.3px;
        }

        &:after {
          height: 1.3px;
        }

      }
    }


    .tabs-secondary {
      flex: 0 33%;
      display: flex;
      padding-right: 20px;

      @media (max-width: $breakpoint-mobile) {
        padding-right: 7px;
      }

      .img img {
        width: 100%;
        max-width: 150px;
        padding-right: 10px;
      }

      .reiter-box {
        //display: flex;
        align-items: center;
        position: relative;

        // Haarlinien der Tab Auswahl-Boxen
        //&:before {
        //  position: absolute;
        //  width: 1px;
        //  height: 100%;
        //  left: 50%;
        //  transform: translateX(-5px);
        //  content: '';
        //  background-color: $color-primary;
        //}
        //
        //&:after {
        //  position: absolute;
        //  width: 100%;
        //  height: 1px;
        //  content: '';
        //  background-color: $color-primary;
        //}

        @media (max-width: 1370px) {
          align-items: flex-start;

          &:before,
          &:after {
            display: none;
          }
        }


        .reiter {
          //display: flex;
          flex-wrap: wrap;
          list-style: none;


          @media (max-width: 1370px) {
            flex-direction: column;
          }

          li {
            flex: 0 48%;
            padding: 5px;
            cursor: pointer;
            min-width: 100px;

            transition: color 0.2s;

            &:hover {
              color: $color-primary;
            }

            &.color {
              color: $color-primary;
            }
          }
        }


        .reiter-mobile {
          display: none;
        }

        @media (max-width: $breakpoint-mobile) {
          .reiter-desktop {
            display: none;
          }

          .reiter-mobile {
            display: block
          }
        }



        .haus-c {
          li:last-child {
            flex: 0 100%;
          }
        }
      }

      @media (max-width: $breakpoint-medium) {
        flex-direction: column;

        .box-25 {
          width: 100%;
        }
        .box-75 {
          width: 100%;
        }

        .img img {
          max-width: 100px;
        }
      }
    }
  }


  // single tab  - styles
  .house-detail {
    display: flex;
    align-items: baseline;

    a {
      display: flex;
      align-items: baseline;
      margin: 0 auto;
    }

    .house-grundriss {
      width: 90%;
      height: auto;
      max-height: 700px;
      @media (max-width: $breakpoint-medium-large) {
        max-height: 600px;
      }
    }


    .lupe {
      width: 10%;
      height: auto;
    }
  }

  .house-description {
    display: flex;
    flex-direction: column;

    h5 {
      font-size: 1.5rem;
      font-weight: 700;
    }


    ul {
      list-style: none;

      @media (max-width: $breakpoint-small) {
        margin-top: 5%;
      }

      li {
        display: flex;

        &:last-child {
          margin-top: 15px;
          p {
            font-weight: 800;
          }
        }

        @media (max-width: $breakpoint-smaller) {
          //flex-direction: column;

          p:nth-child(2) {
            padding-bottom: 3%;
          }

        }


        p:first-child {
          // gleicher Abstand wie der Button
          width: 190px;
          font-weight: 500;

          flex-shrink: 0;
        }

        p {
          margin-bottom: 1%;
        }
      }
    }

    .button {
      margin: 10% auto 0 auto;

      @media (max-width: $breakpoint-small) {
        margin: 5% 0 2% 0;
      }
    }
  }


  .wohnung-seite {
    width: 120px;
    margin: 20px 0;
  }



}