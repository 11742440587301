main section,
.section-confirm,
.section-legal {
  padding: 4% 0;

  @media (max-width: $breakpoint-smaller) {
    padding: 10% 0;

  }
}

.color-section {
  background-color: $color-secondary-pale;
}

section:last-child {
  padding-bottom: 10%;
}


section {
  a {
    font-weight: bold;
    text-decoration: underline;
    color: $color-primary;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-secondary;
    }
  }
}
